import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export default function PolicyEquality() {
  return ( 
    <Layout>
        <section className="container">
            <h1>Equality Policy <a href="downloads/LCJFC Equality Policy.pdf" target="_blank" title="download policy"><FontAwesomeIcon className="lcj-color" icon={faFileDownload}></FontAwesomeIcon></a></h1>
            <InnerPageTwitterLayout>
<div className="small">
<h5>We all have a responsibility to promote high standards of behaviour in the game. </h5>
<p>
1. The aim of this policy is to ensure that everyone is treated fairly and with respect and that our club is equally accessible to all.<br/>
2. This policy is fully supported by the Club Officers who are responsible for the implementation of this policy.<br/>
3. Our commitment is to confront & eliminate discrimination whether by reason of age, gender, gender reassignment, sexual orientation, marital status, race, nationality, ethnic origin, colour, religion or belief, ability or disability and to encourage equal opportunities.<br/>
4. LCJFC is responsible for setting standards & values to apply throughout the club at every level. Football belongs to & should be enjoyed by anyone who wants to participate in it.<br/>
5. LCJFC in all its activities will not discriminate in any way, treat anyone less favorably on grounds of age, gender, gender reassignment, sexual orientation, marital status, race, nationality, ethnic origin, colour, religion or belief, ability or disability. The club will ensure it treats people fairly and with respect and that it will provide access and opportunities for all members of the community to take part in and enjoy its activities.<br/>
6. LCJFC will not tolerate harassment, bullying, abuse or victimization of an individual, which for the purposes of this policy and the actions and sanction applicable is regarded as discrimination.<br/>
7. This includes sexual or racially based harassment or other discriminatory behavior, whether physical or verbal. The club will work to ensure such behaviour is met with appropriate action in whatever context it occurs.<br/>
8. LCJFC is committed to taking positive action where equalities exist and to the development of a programme of ongoing training and awareness raising events and activities in order to promote the eradication of discrimination and promote equality in football.<br/>
9. LCJFC is committed to a policy of equal treatment of all members and requires all members to abide and adhere to these policies and the requirements of the Equality Act 2010.<br/>
10. LCJFC commits itself to the immediate investigation of any claims when brought to its attention, of discrimination on the above grounds and where such is found to be the case, a requirement that the practice stop and sanctions are imposed as appropriate.<br/>
</p>

</div> 
            </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
